@font-face {
    font-family: 'poppinsextrabold';
    src: url('#{$fonts}/popins-extrabold/poppins-extrabold-webfont.eot');
    src: url('#{$fonts}/popins-extrabold/poppins-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/popins-extrabold/poppins-extrabold-webfont.woff2') format('woff2'),
         url('#{$fonts}/popins-extrabold/poppins-extrabold-webfont.woff') format('woff'),
         url('#{$fonts}/popins-extrabold/poppins-extrabold-webfont.ttf') format('truetype'),
         url('#{$fonts}/popins-extrabold/poppins-extrabold-webfont.svg#poppinsextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
	font-display: swap;
}


@font-face {
    font-family: 'poppinsregular';
    src: url('#{$fonts}/poppins-regular/poppins-regular-webfont.eot');
    src: url('#{$fonts}/poppins-regular/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/poppins-regular/poppins-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/poppins-regular/poppins-regular-webfont.woff') format('woff'),
         url('#{$fonts}/poppins-regular/poppins-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/poppins-regular/poppins-regular-webfont.svg#poppinsregular') format('svg');
    font-weight: normal;
    font-style: normal;
	font-display: swap;
}
