@import "./topo__main";
@import "./menu";
@import "./search";
@import "./backdrop";
@import "./mobile__controls";

.topo{
	@include media-breakpoint-down(md) {
		padding-top: 70px;
	}

	@include media-breakpoint-up(lg) {
		background: #FFF;
		position: relative;
		border-bottom: 1px solid #CCC;
	}

	.btn-topo-ctrl{
		width: 55px;
		height: 55px;
		border-radius: 50%;
		@include button-variant($darkBlue, $darkBlue);
		padding: 0;
		box-shadow: none !important;
	}
}
