.paginacao{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.page-link{
		color: #FFF;
		background: $darkBlue;
		border-color: $darkBlue;
	}

	.page-item.active .page-link{
		background: $amarelo;
		border-color: $amarelo;
		color: color-contrast($amarelo);
	}
}
