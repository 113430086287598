.card__noticia{
	font-size: 14px;
	position: relative;
	margin-bottom: 30px;

	@include media-breakpoint-down(xs) {
		text-align: center;
	}

	a{
		color: inherit;

		&:hover{
			--opacity: 1;
		}
	}

	@include media-breakpoint-down(sm){
		font-size: 12px;
	}
}

.card__noticia__foto{

	position: relative;
	overflow: hidden;
	z-index: 0;

	&::after{
		content:'';
		display: block;
		padding-bottom: percentage(155 / 230);
	}

	&::before{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 1;
		background: rgba($amarelo, .7);
		opacity: var(--opacity, 0);
		transition: opacity .3s linear;
	}

	img{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
	}
}

.card__noticia__title{
	font-size: toEm(15,14);
	font-family: 'poppinsextrabold';
}

.card__noticia__data{
	margin-bottom: .5rem;
}

.card__noticia__body{
	font-size: 1em;
}

.card__noticia.principal {
	width: 100%;
	max-width: 549px;
	margin-right: auto;
	margin-left: auto;

	.card__noticia__body{
		background: rgba(#000,.7);
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		padding: 15px 0;
		color: #FFF;
		backdrop-filter: blur(4px);
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}

	.card__noticia__content{
		width: 100%;
		max-height: 3em;
		@include line-clamp();
	}

	.card__noticia__title,
	.card__noticia__data,
	.card__noticia__content{
		padding-left: 15px;
		padding-right: 15px;
	}
}

.card__noticia:not(.principal) {
	color: #6c6c6c;

	.card__noticia__foto{
		max-width: 230px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: .5rem;
	}

	a:hover{
		text-decoration: none;
	}

	.card__noticia__title{
		color: #000;
	}

	.card__noticia__content{
		max-height: 6em;
		@include line-clamp(4);
	}
}

@include media-breakpoint-up(md) {
	.card__noticia{
		&:not(.principal) {
			a{
				display: grid;
				grid-template-columns: 230px calc(100% - 245px);
				grid-column-gap: 15px;
			}

			.card__noticia__foto{
				width: 100%;
				max-width: 230px;
			}
		}
	}
}
