.mobile__controls{
	display: grid;
	grid-template-columns: 50px calc(100% - 120px) 50px;
	grid-column-gap: 10px;
	border-bottom: 1px;
	width: 100%;
	background: #FFF;
	z-index: 998;
	left: 0;
	top: 0;
	border-bottom: 1px solid #CCC;
	position: fixed;

	.brand{
		padding: 5px 0;
		margin: auto;

		img{
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 60px;
			object-fit: contain;
		}
	}
}
