@include media-breakpoint-down(md) {
	.search{
		position: fixed;
		left: 50%;
		top: 90px;
		width: calc(100% - 30px);
		max-width: 600px;
		z-index: 1001;
		transform: translate(-50%, calc(-100% + -100px));
		text-align: center;
		color: #FFF;

		&.search--shown{
			transform: translate(-50%, 0);
			animation: searchInMobile .4s linear;
		}

		&.search--hide{
			animation: searchOutMobile .4s linear;
		}
	}

	.input__search{
		position: relative;

		.form-control{
			height: 50px;
			padding-right: 50px;
			padding-left: 25px;
			border-radius: 25px;
			position: relative;
			z-index: 0;

			&::placeholder{
				color: transparent;
			}
		}

		.btn{
			position: absolute;
			top: 1px;
			right: 1px;
			width: 48px;
			height: 48px;
			z-index: 1;
			@include button-variant($amarelo, $amarelo);
			border-radius: 50%;
		}
	}
}

@include media-breakpoint-up(lg) {
	.search{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		max-width: map-get($container-max-widths, xl);
		display: flex;
		justify-content: flex-end;

		&:not(.search--shown) {
			display: none;
		}

		&.search--shown{
			animation: searchInDesktop .4s linear;
		}

		&.search--hide{
			animation: searchOutDesktop .4s linear;
		}

		form{
			width: calc(100% - 200px);
			background: #FFF;
		}

		.input__search{
			padding-right: 15px;
			display: flex;
			align-items: center;
			margin-left: auto;
			padding-left: 15px;
		}

		label{
			@include sr-only;
		}

		.btn{
			width: 55px;
			height: 55px;
			border-radius: 50%;
			@include button-variant($amarelo, $amarelo);
			padding: 0;
			margin-left: 15px;
			flex-shrink: 0;
		}

		.form-control{
			min-height: 55px;
			border-radius: 27px;
			padding-right: 27px;
			padding-left: 27px;
			border-color: #BBB;
			background-clip: unset;
		}
	}
}

@keyframes searchInDesktop{
	from {
		clip-path: inset(0 0 0 calc(100% - 84px));
	}

	to{
		clip-path: inset(0 0 0 0);
	}
}

@keyframes searchOutDesktop{
	to {
		clip-path: inset(0 0 0 calc(100% - 84px));
	}

	from{
		clip-path: inset(0 0 0 0);
	}
}

@keyframes searchInMobile {
	from {
		transform: translate(-50%, calc(-100% + -100px));
	}

	to{
		transform: translate(-50%, 0);
	}
}

@keyframes searchOutMobile {
	to {
		transform: translate(-50%, calc(-100% + -100px));
	}

	from{
		transform: translate(-50%, 0);
	}
}
