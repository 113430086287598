#banner{
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;

	.carousel-indicators li{
		width: 18px;
		height: 18px;
		border:1px solid transparent;
		background: none;
		opacity: 1;
		position: relative;
		border-radius: 50%;
		transition: border .3s linear;

		&::before{
			content:'';
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			left: 2px;
			top: 2px;
			background: #FFF;
			border-radius: 50%;
			position: absolute;
		}

		&.active{
			border-color: #FFF;
			animation: rubberBand .4s linear
		}
	}
}

.medias__index{
	padding-top: 50px;
	padding-bottom: 20px;

	.media{
		margin-bottom: 30px;
		font-size: 14px;
	}

	.media-icon{
		margin-right: 30px;
		flex-shrink: 0;
	}

	.media-title{
		font-family: 'poppinsextrabold';
		margin-bottom: 10px;
	}

	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: repeat(3, calc(
			(100% - 60px) / 3
		));
		grid-gap: 30px;
	}
}

.section__quem__somos{
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;

	.section__quem__somos__text{
		padding: 50px 15px;
		width: 100%;
	}

	.section__quem__somos__image{
		text-align: center;
	}
}

.section__quem__somos__container{
	@include media-breakpoint-up(xl) {
		width: 1920px;
		left: 50%;
		transform: translateX(-50%);
		display: grid;
		grid-template-columns: 975px 945px;
		position: relative;

		.section__quem__somos__text{
			max-width: #{round(map-get($container-max-widths, xl) / 2)};
			margin-left: auto;
			align-self: center;
		}

		.section__quem__somos__image{
			position: relative;
			overflow: auto;

			img{
				position: sticky;
				top: 0;
			}
		}
	}
}

.section__depoimentos{
	padding-top: 50px;
	overflow: hidden;
	width: 100%;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
	text-align: center;

	.depoimento{
		background: #FFF;
		padding: 15px;
	}

	.depoimento__autor{
		font-family: 'poppinsextrabold';
		font-size: 16px;
		color: #000;
		margin-bottom: 20px;
	}

	.depoimento__text{
		color: #6c6c6c;
	}

	.depoimento__avatar{
		width: 80px;
		height: 80px;
		overflow: hidden;
		border-radius: 50%;
		margin-top: 40px;
		margin-left: auto;
		margin-right: auto;
	}
}

.carousel__depoimentos{
	padding-top: 50px;
	padding-bottom: 150px;
	position: relative;
	perspective: 3px;
	background: #FFF;

	&::before{
		content: '';
		display: block;
		width: 100vw;
		height: calc(50% + 40px);
		max-height: 100%;
		background: #cbb9e3;
		position: absolute;
		left: 50%;
		bottom: 0;
		transform: translateX(-50%);
		z-index: -1;
	}

	.carousel-controls{
		padding: 30px;
		background: #FFF;
	}

	.carousel-control-prev,
	.carousel-control-next{
		position: relative;
		opacity: 1;
		@extend .btn;
		width: auto;
	}

	.carousel-control-prev-icon,
	.carousel-control-next-icon {
		background: none !important;
		@extend .fas;
		color: #000;

		&:hover{
			color: $amarelo
		}
	}

	.carousel-control-prev-icon{
		@extend .fa-angle-double-left;
	}

	.carousel-control-next-icon{
		@extend .fa-angle-double-right;
	}
}

.section__noticias{
	padding-top: 50px;

	hgroup{
		text-align: center;
	}
}

.section__contato{
	padding-top: 50px;

	@include media-breakpoint-down(sm) {
		text-align: center;
	}

	&::before{
		content:'';
		width: calc(100% - 30px);
		max-width: #{map-get($container-max-widths, xl) - 30px};
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 50px;
		border-top: 1px solid #DDD;
		display: block;
	}

	.container{

		@include media-breakpoint-up(md) {
			display: grid;
			grid-template-columns: repeat(2, calc(50% - 15px));
			grid-column-gap: 30px;
			align-items: center;

			.btn{
				margin-left: auto;
			}
		}
	}

	.embed-responsive{
		max-height: 400px;
		min-height: 200px;
		background: #EEE;

		&::before{
			padding-bottom: percentage(400/1920);
		}
	}

	.container{
		margin-bottom: 50px;
	}
}
