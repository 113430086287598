@include media-breakpoint-up(lg) {
	.topo__main{
		width: 100%;
		max-width: map-get($container-max-widths, xl);
		padding: 15px;
		margin-left: auto;
		margin-right: auto;
		display: grid;
		grid-template-columns: 200px calc(100% - 315px) 55px;
		grid-column-gap: 30px;
		align-items: center;
	}
}

@include media-breakpoint-down(md) {
	.topo__main{
		width: 250px;
		height: 100%;
		background: #FFF;
		left: 0;
		top: 0;
		overflow: auto;
		z-index: 1001;
		position: fixed;

		&:not(.topo__main--shown) {
			transform: translateX(-100%);
		}

		&.topo__main--shown{
			animation: slideInLeft .4s linear;
		}

		&.topo__main--hide{
			animation: slideOutLeft .4s linear;
		}

		.brand{
			padding: 30px 15px;
		}
	}
}
