.galeria-fotos#modelo-3{
	.album-fotografia{
		width: 350px;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 30px;
		position: relative;

		.album-caption{
			position: absolute;
			padding:20px;
			width: 100%;
			height: 100%;
			background: rgba(#000, 0.68);
			left:0;
			top: 0;
			display: flex;
			flex-direction: column;
			justify-content:center;
			text-align: center;
			opacity: 0;
			transition: opacity 0.3s linear;

			&:before{
				content:'';
				width: calc(100% - 20px);
				height: calc(100% - 20px);
				position: absolute;
				border:1px solid #fff;
				left: 10px;
				top: 10px;
			}
		}

		.nome{
			color: #FFF;
		}

		.description{
			color: #ff9600;
		}

		&:hover{
			.album-caption{
				opacity: 1;
			}
		}
	}
}


.galeria-fotos{
	.album{
		width: 100%;
		height: 100%;
		top:0;
		left:0;
		position: fixed;
		z-index: 1024;
		background: rgba(#000,0.8);
		display: none;

		&.show{
			display: block;
			animation: fadeIn 0.6s linear;
		}

		.content{
			width: calc(100vh - 200px);
			max-width: calc(100vw - 100px);
			position: relative;
			top:50%;
			transform: translateY(-50%);
			margin-left: auto;
			margin-right: auto;
			z-index: 5;
		}

		.fechar{
			color:#FFF;
		}

		.header{
			text-align: right;
			min-height: 40px;
		}

		.carousel{
			max-width: calc(100vh - 200px);
		}

		.wrapper-carousel{
			position: relative;
		}

		.prev,
		.next{
			width:30px;
			height: 30px;
			display: inline-flex;
			align-items:center;
			justify-content:center;
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			background: #FFF;
			border-radius: 100%;

			&:hover{
				text-decoration: none;
			}
		}

		.prev{
			right: calc(100% + 10px);
		}

		.next{
			left: calc(100% + 10px);
		}

		.carousel-indicators{
			position: static;
			bottom: 0;
			width: 100%;
			margin-right: 0;
			margin-left: 0;
			flex-wrap: nowrap;
			overflow: auto;
			min-height: 130px;
			justify-content: flex-start;

			li{
				width: 100px;
				min-width: 100px;
				height: auto;
				text-indent: 0;
				background: none;
			}
		}
	}
}
