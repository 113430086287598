.rodape{
	background: $darkBlue;
	color: #FFF;
	padding-top: 30px;

	a{
		color: inherit;

		&:hover{
			color: $amarelo;
		}

		&.link-termos{
			color: $amarelo;
			text-decoration: underline;
		}
	}

	.brand{
		margin-bottom: 30px;

		@include media-breakpoint-down(lg) {
			text-align: center;
		}
	}
}

.newsletter__footer{
	.custom-control-input:checked~.custom-control-label::before{
		background: $amarelo;
		border-color: $amarelo;
	}
	.custom-control-input:checked~.custom-control-label::after{
		background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>");
	}

	.form-group{
		margin-bottom: 10px;
	}

	.form-control{
		border-radius: 2rem;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.message__validation{
		padding: 10px;
		border-radius: 4px;
		display: inline-block;
		max-width: 100%;
		margin-top: 10px;
		position: relative;
		border: 1px solid transparent;
		filter: drop-shadow(0 0 4px rgba(#000,.8));

		&::before{
			content:'';
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid transparent;
			position: absolute;
			left: 50%;
			bottom: 100%;
			margin-left: -10px;
		}
	}

	$states: (
		'success' : $success,
		'warning' : $amarelo,
		'error'   : $danger
	);

	@each $state,$value in $states {
		.has__#{$state} {
			.message__validation{
				background: $value;
				color: color-contrast($value);

				&::before{
					border-bottom-color: $value;
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.btn{
			width: 100%;
			@include button-variant($amarelo, $amarelo);
			border-radius: 2rem;

			span{
				margin-right: 5px;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.btn{
			position: absolute;
			top: 1px;
			right: 1px;
			width: 36px;
			height: 36px;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 1;
			border-radius: 50%;
			color: $amarelo;
			background: #FFF;
			border-color: #FFF;

			&:hover{
				color: #000;
				background: $amarelo;
				border-color: $amarelo;
			}

			span{
				@include sr-only;
			}
		}

		.relative .form-control{
			position: relative;
			z-index: 0;
			padding-right: 48px;
		}
	}
}

.redes__sociais{
	font-family: 'poppinsextrabold';
	display: flex;
	align-items: center;
}

.redes__sociais__content{
	padding: 0 5px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	.fab{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 2em;
		height: 2em;
		border-radius: 50%;
		border: 1px solid #FFF;
		color: #FFF;
		margin: 0 5px;
		transition: all .3s linear;

		&:hover{
			background: #FFF;
			color: $amarelo;
			text-decoration: none;
		}
	}
}

.footer__title{
	font-family: 'poppinsextrabold';
	font-size: 15px;
	margin-bottom: 15px;
}

.creditos{
	padding: 15px 0;
	background: #cbb9e3;
	color: #FFF;
	font-size: 12px;

	a{
		color: inherit;
		transition: color .3s linear;

		&:hover{
			color: #000;
			text-decoration: none;
		}
	}

	svg{
		width: toEm(55,12);
		height: toEm(21, 12);
		fill: currentColor;
	}

	@include media-breakpoint-up(lg) {
		.container{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}

.media__footer{
	margin-bottom: 10px;

	.media-icon{
		line-height: 1.5;
		margin-right: 10px;
	}
}
