.internas{
	padding-bottom: 80px;

	.content__editable{
		h1,
		h2,
		h3,
		h4,
		h5,
		h6{
			font-family: 'poppinsextrabold', sans-serif;
		}
	}
}
