.main__menu{
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;

	a{
		color: inherit;
	}
}

@include media-breakpoint-up(lg) {
	.main__menu{
		display: flex;
		justify-content: space-between;
		font-size: 14px;

		.active a,
		a:hover{
			color: $darkBlue;
			text-decoration: none;
		}
	}
}

@include media-breakpoint-down(md) {
	.main__menu{
		a{
			display: block;
			padding: 10px 15px;

			&:hover{
				background: #EEE;
				text-decoration: none;
			}
		}

		.active a,
		.active a:hover{
			background: $amarelo;
			color: color-contrast($amarelo);
			text-decoration: none;
		}
	}
}
