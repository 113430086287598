.btn-pill{
	border-radius: 2rem;
	padding: .5rem 2rem;
}

.btn-voltar{
	padding: .5rem 2rem;
	min-width: 190px;
	@include button-variant($darkBlue, $darkBlue);
}
