@import "_fontfaces";

body{
	font-family: 'poppinsregular';
	font-size: 14px;
}

hgroup{
	font-size: 15px;
	margin-bottom: 30px;

	@include media-breakpoint-down(xs) {
		text-align: center;
	}

	.h1{
		display: inline-block;
		border-left: 4px solid currentColor;
		padding-left: 10px;
		color: $amarelo;
		font-size: 1em;
	}

	.h2{
		font-size: 2em;
		font-weight: 400;

		b{
			font-family: 'poppinsextrabold';
		}
	}
}
